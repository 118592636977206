import React from "react"

import colors from "../colors"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import ImageGrid from "@components/ImageGrid"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  DownloadProductBrochureButton,
} from "../components/buttons"

const recentProjectItems = [
  {
    image: `/images/projects/ritz-carlton-project-home.jpg`,
    text: "Ritz-Carlton Key Biscayne, Miami, FL",
    url: `/textured-pattern-glass-ritz-carlton-miami-fl`,
    position: "left",
    featured: true,
  },
  {
    image: `/images/projects/top-draft-project1.jpg`,
    text: "Top Draft Sports Bar, Atlanta, GA",
    url: `/back-painted-glass-atlanta-ga-top-draft-sports-bar`,
    position: "right",
  },
  {
    image: `/images/projects/westgate-resorts-project1.jpg`,
    text: "Westgate Resorts, Orlando, FL",
    url: `/laminated-glass-orlando-fl-westgate-resorts`,
    position: "left",
  },
  {
    image: `/images/projects/pelicans-nest-project1.jpg`,
    text: `Pelican’s Nest Golf Club, Bonita Springs, FL`,
    url: "/pattern-glass-pelicans-nest-golf-club-bonita-springs-fl",
    position: "right",
  },
]

const Projects = () => {
  return (
    <PageLayout>
      <SEO title="Projects | ArtVue Glass" ogDescription="At ArtVue Glass we specialize in custom projects. We provide our customers with the highest quality of workmanship and service at an affordable price. Our goal is to meet or exceed your expectations on every project that comes through our doors." />
      <div style={{ margin: "3em 0" }}>
        <ImageGrid
          content={recentProjectItems}
          title="Projects"
          description="At ArtVue Glass, our custom projects start with one of our product families and then expand from there. Whether you are looking for popular types of glass or some hard-to-find items, we carry a large inventory so you can find the perfect materials for any type of job!"
          itemsPerRow={2}
        />
      </div>
    </PageLayout>
  )
}

export default Projects
